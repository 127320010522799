import "core-js/modules/es6.string.fixed";
import staticQueryData from "../../public/static/d/2998135601.json";

/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import Image from "gatsby-image";
import { rhythm } from "../utils/typography";

function Bio() {
  var data = staticQueryData.data;
  var _data$site$siteMetada = data.site.siteMetadata,
      author = _data$site$siteMetada.author,
      social = _data$site$siteMetada.social;
  return React.createElement("div", {
    style: {
      display: "flex"
    }
  }, React.createElement(Image, {
    fixed: data.avatar.childImageSharp.fixed,
    alt: author,
    style: {
      marginRight: rhythm(1 / 2),
      marginBottom: 0,
      minWidth: 50,
      borderRadius: "100%"
    },
    imgStyle: {
      borderRadius: "50%"
    }
  }), React.createElement("p", null, "A fair attempt to express ", React.createElement("strong", null, author), "'s opinion about stuff that he works with, hates or enjoys.", " ", React.createElement("a", {
    href: "https://twitter.com/" + social.twitter
  }, "You can find him on Twitter.")));
}

export default Bio;
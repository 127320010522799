import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.string.fixed";
import staticQueryData from "../../public/static/d/3961034539.json";
import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { rhythm, scale } from "../utils/typography";

function Layout(props) {
  var data = staticQueryData.data;
  var location = props.location,
      title = props.title,
      children = props.children;
  var rootPath = __PATH_PREFIX__ + "/";
  var headerTitle = React.createElement(Link, {
    style: {
      boxShadow: "none"
    },
    to: "/"
  }, React.createElement(Image, {
    fixed: data.logo.childImageSharp.fixed,
    alt: title
  }));
  var header = location.pathname === rootPath ? React.createElement("h1", {
    style: Object.assign({}, scale(1.5), {
      marginBottom: rhythm(1.5),
      marginTop: 0,
      textAlign: "center"
    })
  }, headerTitle) : React.createElement("h3", {
    style: {
      fontFamily: "Montserrat, sans-serif",
      marginTop: 0,
      textAlign: "center"
    }
  }, headerTitle);
  return React.createElement("div", {
    style: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: rhythm(24),
      padding: rhythm(1.5) + " " + rhythm(3 / 4)
    }
  }, React.createElement("header", null, header), React.createElement("main", null, children), React.createElement("footer", {
    style: {
      textAlign: "center",
      color: "#999"
    }
  }, "\xA9 Copyright ", new Date().getFullYear(), ",", " ", React.createElement("a", {
    href: "https://macambira.co"
  }, "Walter Macambira"), ". Powered by", " ", React.createElement("a", {
    href: "https://www.gatsbyjs.org"
  }, "Gatsby"), "."));
}

export default Layout;
import Typography from "typography";
import Wordpress2016 from "typography-theme-wordpress-2016";

Wordpress2016.overrideThemeStyles = function () {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: "none"
    }
  };
};

delete Wordpress2016.googleFonts;
var typography = new Typography(Wordpress2016); // Hot reload typography in development.

if (process.env.NODE_ENV !== "production") {
  typography.injectStyles();
}

export default typography;
export var rhythm = typography.rhythm;
export var scale = typography.scale;